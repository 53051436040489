<template>
  <div id="app">
    <router-view></router-view>

    <div class="header">
      <van-tabs v-model="active" @click="changeTabs(active)" color="#1989fa" type="card" style="margin-top: 1rem">
        <van-tab title="必修抢课"/>
        <van-tab title="选修抢课"/>
        <van-tab title="公选课抢课"/>
        <van-tab title="辅修抢课"/>
      </van-tabs>
      <van-search clearable show-action v-model="search" placeholder="请输入课程名称关键词" @search="onSearch">
        <template #action>
          <div @click="onClickButton">搜索</div>
        </template>
      </van-search>
      <van-dropdown-menu v-show='xq'>
        <van-dropdown-item v-model="value1" :options="option1" @change="xqchange()"/>
      </van-dropdown-menu>
      <van-dropdown-menu v-show='xq1'>
        <van-dropdown-item v-model="value2" :options="option2" @change="xqchange()"/>
      </van-dropdown-menu>

      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
        <van-cell-group style="margin-top: 1rem">

          <van-cell v-for="item in list" :key="item.id">
            <div style="display: flex;flex-direction: row;flex-wrap: nowrap">
              <div class="left_content" style="display: flex ;flex-flow: row wrap;">
                <span
                    style="max-width: 25vh;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{
                    item.kcmc
                  }}</span>
                <span style="">授课老师:{{ item.skls }} 学分:{{ item.xf }}</span>
                <span style="">{{ item.xqmc }} {{ item.sksj }}</span>
              </div>
              <div class="right_content"
                   style="width: 100%;display: flex;justify-content: flex-end;align-items: center">
                <van-button :type="item.ctsm?'danger':'primary'"
                            @click="item.ctsm?tipsCt(item):addGrabCourse(item)" :value="item.jx0404id" size="small">
                  {{ item.ctsm ? '课程冲突' : '加入抢课' }}
                </van-button>
              </div>
            </div>


          </van-cell>
        </van-cell-group>
      </van-list>
    </div>
    <van-tabbar v-model="active" route>
      <van-tabbar-item to="/home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item to="/grabclass" :icon="require('@/assets/course.png')">抢课</van-tabbar-item>
      <van-tabbar-item to="/my" icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {Image as VanImage, Tabbar, TabbarItem, Toast} from 'vant';
import {Button} from 'vant';
import {Tab, Tabs} from 'vant';
import {Search} from 'vant';
import {Cell, CellGroup} from 'vant';
import {List} from 'vant';
import {Dialog} from 'vant'
import {addCourse, getBxk, getGxk} from "../request/api";
import {DropdownMenu, DropdownItem} from 'vant';

export default {
  name: "GrabClass"
  , components: {
    [Tabbar.name]: Tabbar,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
    [VanImage.name]: VanImage,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      search: "",
      page: 1,
      list: [],
      //控制加载中loading状态
      loading: false,
      //控制数据加载结束的状态
      finished: false,
      //请求获取下一页数据的时间戳
      timestamp: null,
      active: 0,
      value1: 0,
      xq: false,
      tabChange: false,
      xq1: false,
      kc: false,
      option1: [
        {text: '请选择', value: 0},
        {text: '南院', value: 1},
        {text: '北院', value: 2},
        {text: '呈贡', value: 3},
        {text: '安宁', value: 'E298641275B7471181C291FA9BC76452'},
      ],
      value2: 0,
      option2: [
        {text: '请选择', value: 0},
        {text: '文化传承与世界视野', value: 1},
        {text: '哲学智慧与科学思维', value: 2},
        {text: '科技发展与世界进步', value: 3},
        {text: '艺术审美与体验', value: 4},
        {text: '法学类', value: 5},
        {text: '人文艺术类', value: 6},
        {text: '科学技术类', value: 7},
        {text: '职必选', value: 8},
        {text: '经济管理类', value: 9},
        {text: '理工类', value: 10},

      ],
    }
  },
  created() {
    // console.log("onLoad")
    // this.showData();
  },
  methods: {
    onLoad() {
      console.log("onLoad")
      if (!this.tabChange) {
        this.showData();
      }
      this.tabChange = false
    }

    , onHandleData(data, active) {
      if (this.active !== active) {
        this.list = []
        this.finished = true
        return
      }
      if (data.code === 4) {
        Toast({
          message: data.msg,
          duration: 1000,
          forbidClick: true
        });
        this.finished = true
        return;
      }
      const results = data.data.data
      this.list.push(...results)
      this.loading = false
      console.log(results.length)
      if (results.length) {
        // 更新获取下一页数据的时间戳
        this.timestamp = 0
        this.page++;
      } else {
        // 没有数据了，将finished设置为true,不再load加载更多
        this.finished = true
      }
      if ((this.page - 1) * 15 > data.data.iTotalRecords) {
        this.finished = true
      }
    }
    , onSearch() {
      console.log("onSearch")
      this.page = 1
      this.finished = false
      this.list = []
      this.showData()
    }
    , showData() {
      switch (this.active) {
        case 0:
          this.xq1 = false;
          this.xq = true;
          this.finished = true
          getBxk({
            'page': this.page,
            'search': this.search,
            'skxq_xx0103': this.value1 === 0 ? '' : this.value1,
          }).then(data => {
            this.onHandleData(data, 0)
          });
          break
        case 1:
          this.finished = true
          this.xq1 = false;
          this.xq = false;
          break
        case 2:
          this.xq1 = true;
          this.xq = false;
          getGxk({
            'page': this.page,
            'search': this.search,
            'szjylb': this.value2 === 0 ? '' : this.value2,
          }).then(data => {
            this.onHandleData(data, 2)
          });
          break
        case 3:
          this.finished = true
          this.xq1 = false;
          this.xq = false;
          break
      }

    }
    , onClickButton() {
      console.log("onSearch")
      this.page = 1
      this.finished = false
      this.list = []
      this.showData()
    }
    , changeTabs() {
      console.log("changeTabs")
      this.tabChange = true;
      this.page = 1
      this.finished = false
      this.search = ''
      this.list = []
      this.showData()
      // console.log(active)
    }
    , xqchange() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      console.log("xqchange")
      this.page = 1
      this.finished = false
      this.list = []
      this.showData()
      // console.log(active)
    }
    , addGrabCourse(item) {
      console.log(item)
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      addCourse({
        'name': item.kcmc,
        'kcid': item.kch,
        'jx0404id': item.jx0404id,
        'tercher': item.skls,
        'class_time': item.sksj,
        'class_type': this.active,
        'xf': item.xf,
        'xqmc': item.xqmc,
      }).then(data => {
        Toast.clear();
        if (data.code === 0) {
          Dialog.alert({
            title: '提示',
            message: item.kcmc + "已经成功加入抢课,消耗100金币"
          }).then(() => {

              }
          )
        }
      });
    },
    tipsCt(item) {
      Toast({
        message: item.ctsm,
        duration: 1000,
        forbidClick: true
      });
    }
  }
}
</script>

<style scoped>
.header {
  margin: 2rem 1rem;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 80px;
}
</style>
<template>
  <div id="app">

    <router-view></router-view>
    <div class="header">
      <div class="content">
        <van-image
            round
            width="5rem"
            height="5rem"
            src="http://q.qlogo.cn/headimg_dl?dst_uin=2630706486&spec=100&img_type=png"
        />

        <div class="stu_content">
          <div style="display: inline-block">{{xm}}</div>
          <div style="display: inline-block">{{department}}</div>
        </div>
      </div>

    </div>
   <div class="middle">
     <van-cell-group style="margin-top: 1rem">
       <van-cell  title="金币" value="金币">{{coin}}</van-cell>
       <van-cell is-link title="金币兑换" value="" />
       <van-cell is-link title="我的抢课" :value="course_num" />
       <van-cell is-link title="抢课成功通知邮箱" :value="email" />
       <van-cell is-link title="首次注册时间" :value="registr_time" />
       <van-cell is-link @click="logout()" title="退出登录"  />
     </van-cell-group>
   </div>
    <van-tabbar v-model="active" route>
      <van-tabbar-item to="/home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item to="/grabclass" :icon="require('@/assets/course.png')">抢课</van-tabbar-item>
      <van-tabbar-item to="/my" icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>

  </div>
</template>

<script>
import {Tabbar, TabbarItem} from 'vant';
import {Image as VanImage} from 'vant';
import {Cell, CellGroup} from 'vant';
import ref from "vue";
import {  Dialog } from 'vant'
import {getInfo} from "../request/api";

export default {
  name: "My"
  , components: {
    [Dialog.Component.name]: Dialog.Component,
    [Tabbar.name]: Tabbar,
    [VanImage.name]: VanImage,
    [TabbarItem.name]: TabbarItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  setup() {
    const active = ref(0);
    return {active};
  },
  data() {
    return {
      active: 0,
      xm:this.$store.state.xm,
      department:this.$store.state.department,
      email:this.$store.state.email,
      coin:this.$store.state.coin,
      registr_time:this.$store.state.registr_time,
      course_num:this.$store.state.course_num
    }
  },
  created() {
    getInfo()
        .then(data=>{
          this.$store.state.xm=data.data.xm;
          this.$store.state.department=data.data.department;
          this.$store.state.email=data.data.email;
          this.$store.state.coin=data.data.coin+"金币";
          this.$store.state.registr_time=data.data.registr_time;
          this.$store.state.course_num=data.data.course_num+"门";
          this.xm= this.$store.state.xm;
          this.department= this.$store.state.department;
          this.email= this.$store.state.email;
          this.coin= this.$store.state.coin;
          this.registr_time= this.$store.state.registr_time;
          this.course_num= this.$store.state.course_num;
          localStorage.setItem("xm",data.data.xm)
          localStorage.setItem("department",data.data.department)
          localStorage.setItem("email",data.data.email)
          localStorage.setItem("coin",data.data.coin+"金币")
          localStorage.setItem("registr_time",data.data.registr_time)
          localStorage.setItem("course_num",data.data.course_num+"门")
        })
  },
  methods: {
    logout(){
      Dialog.confirm({
        title: '提示',
        message: '退出登录'
      }).then(() => {
        this.$router.push({
          path:'login'
        })
        // on confirm
      }).catch(() => {
        // on cancel
      });
    }
  }
}
</script>

<style scoped>
.header {
  margin: 2rem 1rem;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
}

.middle {
  margin: 1rem 1rem;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
}

.header .content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-left: 1.5rem;
  padding: 1rem;
  width: 100%;
}

.stu_content {
  width: max-content;
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
  margin-top: -10px;
}

.stu_content div {
  text-align: center;
  font-weight: bold;
  font-size: large;
  margin-top: 10px;
}


</style>
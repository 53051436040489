import Vue from 'vue'
import Router from 'vue-router'
import Login from "../page/Login";
import Home from "../page/Home";
import GrabClass from "../page/GrabClass";
import My from "../page/My";

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default new Router({
    routes: [
        {
            path:'/home',
            component:Home
        },
        {
            path:'/grabclass',
            component:GrabClass
        },
        {
            path:'/my',
            component:My
        },
        {
            path:'/login',
            component:Login
        },
        {
            path:'/',
            component:Login
        }
    ],
    mode:"history"
})
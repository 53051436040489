<template>
  <div id="app">

    <router-view></router-view>
    <lottie :options="defaultOptions" :height="300" :width="300" v-on:animCreated="handleAnimation" />
    <div style="width: 100%;display: flex;justify-content: center;flex-direction: column;align-items: center">
       <span style="font-size: xx-large;width:100%;text-align: center">寒♥辉</span>
      <span style="font-size: large;width:100%;text-align: center;margin-top: 8vh">点击祝福将获得随机金币奖励</span>
      <lottie v-show="show_zhufu" :options="defaultOptions1" :height="100" :width="300" v-on:animCreated="handleAnimation" />

      <van-button round block type="warning" style="height: 3.5rem;width: 20vh;margin-top: 20px" @click="zhufu()">
        祝福
      </van-button>
         </div>
    <van-tabbar v-model="active" route>
      <van-tabbar-item to="/home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item to="/grabclass" :icon="require('@/assets/course.png')">抢课</van-tabbar-item>
      <van-tabbar-item to="/my" icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {Dialog, Tabbar, TabbarItem} from 'vant';

import {Button} from 'vant'
import animationData from "../assets/lottie/love_together.json";
import animationData1 from "../assets/lottie/love.json";
import ref from "vue";
import {bless} from "../request/api";

export default {
  name: "Home"
  , components: {
    [Button.name]: Button,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  created() {
    // eslint-disable-next-line no-unused-vars

  },
  setup() {
    const active = ref(0);
    return {active};

  },
  data() {
    return {
      active: 0,
      defaultOptions: { animationData: animationData },
      defaultOptions1: { animationData: animationData1 },
      show_zhufu:false,
    }
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
      console.log(anim); //这里可以看到 lottie 对象的全部属性
    },
    zhufu(){
      this.show_zhufu=true;

      bless().then(data=>{
        if(data.code===0){
          Dialog.alert({
            title: '祝福辉寒',
            message: "祝福成功，获得"+data.data.coin+"金币奖励",
            confirmButtonText:"收起金币"
          }).then(() => {

              }
          )
        }

      })


    }
  }
}
</script>

<style scoped>

</style>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    //state中存放的就是全局共享的数据
    state: {
        xm:localStorage.getItem("xm"),
        department:localStorage.getItem("department"),
        email:localStorage.getItem("email"),
        coin:localStorage.getItem("coin"),
        registr_time:localStorage.getItem("registr_time"),
        course_num:localStorage.getItem("course_num"),
        token:localStorage.getItem("token"),
    },
    mutations: {},
    actions: {}
})


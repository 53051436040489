import axios from 'axios';
import {Dialog, Toast} from 'vant';
import router from "../router/router";
import qs from "qs";

// 请求超时时间
axios.defaults.timeout = 10000;
// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.baseURL = 'https://hanmeet.qmdbao.com/';
// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = localStorage.getItem("token");
        token && (config.headers.Authorization = token);
        return config;
    },
    error => {
        return Promise.error(error);
    });

axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            switch (response.data.code){
                case 101:
                    break
                case 102:
                    break
                case 103:
                    Dialog.alert({
                        title: '提示',
                        message: '登录过期，请重新登录',
                        confirmButtonText:"重新登录"
                    }).then(() => {
                        router.push({
                            path: '/login'
                        });
                        }
                    )
                    break
                case 104:
                    Dialog.alert({
                        title: '账号封停',
                        message: response.data.msg,
                        confirmButtonText:"联系我们"
                    }).then(() => {
                            window.open("mqqwpa://im/chat?chat_type=wpa&uin=2630706486");
                        }
                    )
                    break
                case 302:
                case 303:
                    Toast.fail(response.data.msg);
                    break;
                case 401:
                case 402:
                case 403:
                    Toast.fail(response.data.msg);
                    break;
                default:
                    return Promise.resolve(response);
            }
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    router.push({
                        path: '/login'
                    });
                    break;
                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                case 103:
                    Toast({
                        message: '登录过期，请重新登录',
                        duration: 1000,
                        forbidClick: true
                    });
                    // 清除token
                    localStorage.removeItem('token');
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    setTimeout(() => {
                        router.push({
                            path: '/login'
                        });
                    }, 1000);
                    break;
                // 404请求不存在
                case 104:
                    Dialog.alert({
                        title: '提示',
                        message: '登录已过期'
                    }).then(() => {
                            window.open("http://www.baidu.com/", "_search");
                        }
                    )
                    break
                case 304:
                    Toast({
                        message: '网络请求不存在',
                        duration: 1500,
                        forbidClick: true
                    });
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    Toast({
                        message: error.response.data.message,
                        duration: 1500,
                        forbidClick: true
                    });
            }
            return Promise.reject(error.response);
        }
    }
);

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}


/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: url,
            data: qs.stringify(params)
        }).then(function (res) {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        });
    });
}
<template style="background-color: #fff;margin: 0;padding: 0">

  <div id="app">
    <div class="icon">
      <van-icon class="logo" size="80" :name="require('@/assets/course_icon.png')"/>
      <div class="app_name">云财抢课助手</div>
    </div>
    <van-form class="login_form">
      <van-cell-group inset>
        <van-field style="padding: 16px 16px;background-color: transparent"
            label-width="2.5rem"
            v-model="username"
            name="用户名"
            label="学号"
            clearable
            placeholder="请输入学号"
            :rules="[{ required: true, message: '请输入账号' }]"

        />

        <van-field style="padding: 16px 16px;background-color: transparent"
            label-width="2.5rem"
            v-model="password"
            :type="passwordType"
            name="password"
            clearable
            label='密码'
            placeholder='请输入密码'
            :rules="[{ required: true, message: ''}]">
          <!--利用插槽添加了右侧图标-->
          <template slot="right-icon">
            <van-icon style="padding: 0 8px;line-height: inherit;"
                      :name="passwordType==='password'?'eye-o':'closed-eye'" @click="switchPasswordType"/>
          </template>
        </van-field>





      </van-cell-group>
      <div style="margin: 6vh 1rem 0;">
        <van-button round block type="primary" style="height: 3.5rem" @click="login()">
          登录
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>

import {Button} from 'vant'
import {Form, Field, CellGroup} from 'vant';
import { Checkbox } from 'vant';
import {NavBar} from 'vant';
import {Image as VanImage} from 'vant';
import {Loading} from 'vant';
import {Icon} from 'vant';
import {Toast} from 'vant';
import { login } from '../request/api';// 导入我们的api接口


export default {
  name: 'App',
  components: {
    [Button.name]: Button,
    [Form.name]: Form,
    [Checkbox.name]: Checkbox,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [VanImage.name]: VanImage,
    [Loading.name]: Loading,
    [Toast.name]: Toast,
    [Icon.name]: Icon,
  },
  setup() {
    const username ="";
    const password = "";
    const onSubmit = (values) => {
      console.log('submit', values);
    };

    return {
      username,
      password,
      onSubmit,
    };
  },
  data() {
    return {
      username: '',
      password: '',
      visible: false,
      passwordType: 'password',
      image: "",
    }
  },
  created() {
    let username=localStorage.getItem("username")
    let password=localStorage.getItem("password")
    if (username !== '' || password !==  '') {
      this.username=username;
      this.password=password;
    }

  },
  methods: {
    psw_click_right() {
      this.visible = !this.visible;
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    login() {
      let that=this;
      if (this.username === '' || this.password === '') {
        Toast.fail("学号密码不能为空");
        return;
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      login({
        'username': this.username,
        'password': this.password,
      }).then(data => {
        Toast.clear();
        if (data.code !== 0) {
          Toast.fail(data.msg);
        }else{
          localStorage.setItem('username',that.username);
          localStorage.setItem('password',that.password);

          localStorage.setItem('xm',data.data.xm);
          localStorage.setItem('department',data.data.department);
          localStorage.setItem("email",data.data.email)
          localStorage.setItem("coin",data.data.coin+"金币")
          localStorage.setItem("registr_time",data.data.registr_time)
          localStorage.setItem("course_num",data.data.course_num+"门")
          localStorage.setItem('token',data.data.token);
          that.$router.push({
            path:'home'
          })
        }
      })





    }
  }
}
</script>

<style>




.login_form {
  margin-top: 10vh;
}

.logo {
  display: flex;
  justify-content: center;
}

.app_name {
  margin-top: 1vh;
  text-align: center;
  color: #333333;
  font-weight: bold;
}


.icon {
  height: fit-content;
  margin-top: 10vh;
}

#app {
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: column;

}


</style>

import Vue  from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router/router.js'
import store from './store.js'
import ref from 'vue'
import lottie from 'vue-lottie';
Vue.use(VueRouter)
Vue.component('lottie', lottie)
new Vue({
  render: h => h(App),
  router,
  store,
  ref
}).$mount('#app')
